import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUi from 'element-ui'//element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css'//element-ui的css
import 'vant/lib/index.css'
import units from './units/units';
import {
  Tabbar,
  TabbarItem,
  NavBar,
  Icon,
  DropdownMenu,
  DropdownItem,
  Tab,
  Tabs,
  Rate,
  Field,
  Picker,
  Popup,
  Calendar,
  Cascader,
  RadioGroup,
  Radio,
  Stepper,
  Toast,
  DatetimePicker,
  Uploader,
  Loading
} from 'vant';

Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Rate);
Vue.use(Field);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Calendar);
Vue.use(Cascader);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Stepper);
Vue.use(Toast);
Vue.use(DatetimePicker);
Vue.use(Uploader);
Vue.use(Loading);

Vue.use(ElementUi)
Vue.config.productionTip = false

import axios from './http/http'
// 配置请求根路径
Vue.prototype.$http = axios
axios.defaults.withCredentials = true;

// 引入sha1加密算法文件
import sha1 from './units/sha1'
Vue.prototype.sha1 = sha1

Vue.prototype.$units = units;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
