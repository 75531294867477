import axios from 'axios';
import {
    Message
} from 'element-ui';
// axios实例
const instance = axios.create({
    // baseURL: '/apis',
    // baseURL: 'http://party-buildin.iwbrtest.com.cn',
    baseURL: window.g.baseURL,
    timeout: 10000,
    method: 'post',
    headers: {
        // 'Content-Type': 'application/json;charset=UTF-8',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    // 请求预处理函数 可以把你传的param进行处理
    transformRequest: [
        data => {
            // data 就是你post请求传的值
            // 一下主要是吧数据拼接成 类似get格式
            let params = ''
            for (var index in data) {
                params += index + '=' + data[index] + '&'
            }
            return params
        }
    ]

});
// 请求拦截器
instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('user_token');
        token && (config.headers['user-token'] = token);
        // console.log(config);
        return config;
    }, error => {
        return Promise.error(error)
    },
)
// 响应拦截器
instance.interceptors.response.use(
    response => { //Vue- Promise函数---参数resolve(调用.then方法)-- 参数reject(调用.catch方法)---链式结构
        if (response.status === 200) {
            let result = response;
            var code = Number(result.data.code);
            if (code == -201 || code == -202 || code == -203 || code == -300) {
                var msg = code == -201 ?
                    '请登录后再操作' : code == -202 ?
                        '登录失效，请重新登录' : code == -203 ?
                            '登录已过期，请重新登录' : '';
                console.log(111112222)
                // store.commit('logout')
                // localStorage.removeItem('user_token');
                // console.log(router.currentRoute.fullPath);

                // if(router.currentRoute.fullPath != '/' || router.currentRoute.fullPath != '/page'){
                // router.replace({
                //     path: '/login',
                //     query: {
                //         redirect: router.currentRoute.fullPath
                //     }
                // })
                if (window.screen.width > 700) {
                    Message.error(msg);
                } else {
                    this.$router.push('login')
                }
                // }

            }
            return Promise.resolve(result);
            // return Promise.reject(result);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response.status) {
            var status = Number(error.response.status);
            switch (status) {
                // 未登录
                case -201:
                    Message.error('请登录');
                    localStorage.removeItem('user_token');
                    if (window.screen.width > 700) {
                        this.$emit('needLogin')
                    } else {
                        this.$router.push('/login')
                    }
                    break;
                // token错误
                case -202:
                    Message.error('登录失效');
                    localStorage.removeItem('user_token');
                    if (window.screen.width > 700) {
                        this.$emit('needLogin')
                    } else {
                        this.$router.push('/login')
                    }
                    break;
                // -203 token 过期
                case -203:
                    localStorage.removeItem('user_token');
                    store.commit('logout');
                    Message.error('登录已过期');
                    if (window.screen.width > 700) {
                        this.$emit('needLogin')
                    } else {
                        this.$router.push('/login')
                    }
                    break;
                // 异地登录
                case -300:
                    localStorage.removeItem('user_token');
                    store.commit('logout');
                    if (window.screen.width > 700) {
                        this.$emit('needLogin')
                    } else {
                        this.$router.push('/login')
                    }
                    break;
                // 请求不存在
                case 404:
                    Message.error('请求的资源不存在');
                    break;
                default:
                    Message.error(error.response.data.message)
                    break;
            }
            return Promise.reject(error.response);
        } else {
            return Promise.reject(error.response);
        }
    }
)

export default instance;