<template>
    <div class="guide">
        <div class="login_bg">
            <img :src= loginBg alt="bg">
        </div>
        <div class="login_con_box">
            <div class="login_logo">
                <img :src= loginLogo alt="logo">
            </div>
            <div class="login_wel">
                <p class="wel_p1">欢迎来到</p>
                <p class="wel_p2">{{ loginUnivercity }}</p>
            </div>
            <div class="login_plat">
                <h3>{{titleName}}</h3>
                <div class="platcon">
                    <div class="guide_item">
                        <button @click="goIdentity()">统一身份认证登录</button>
                    </div>
                    <div class="guide_item">
                        <button @click="loginEnter">账号密码登录</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "guide",
        data() {
            return {
                titleName: window.g.titleName,
                loginUnivercity:'',
                loginBg: '',
                loginLogo: '',
                ssoUrl: ''
            }
        },
        beforeMount() {
            if (window.screen.width <= 768) {
                this.$router.push('/login')
            }
        },
        mounted() {
            this.getLoginInfo()//获取信息
        },
        methods: {
            goIdentity() {//统一身份认证登录，外链
                // this.$router.push('/')
                // window.open("http://www.baidu.com","_blank");
                // window.open(this.ssoUrl,'_blank')
                window.location.href = this.ssoUrl
            },
            loginEnter() {
                this.$router.push('/login')
            },
            getLoginInfo() {
                this.$http.get('/api/v1/public/login',{})
                .then((res)=>{
                    if (res.data.data.enable_sso == 1) {
                        console.log("统一身份认证登录已开启")
                        this.ssoUrl = res.data.data.sso_url
                    }else{
                        this.$router.push('/login')
                    }
                    if(res.data.data.pc_background_img !== '') {
                        this.loginBg = res.data.data.pc_background_img;
                    }else {
                        this.loginBg = require('../../assets/images/login_bg.jpg')
                    }
                    if(res.data.data.logo_img !== '') {
                        this.loginLogo = res.data.data.logo_img;
                    }else {
                        this.loginLogo = require('../../assets/images/logo.png')
                    }
                    if (res.data.data.school_name !== '') {
                        this.loginUnivercity = res.data.data.school_name
                    }else {
                        this.loginUnivercity = '中国矿业大学（北京）'
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .guide {
        position: relative;
        .login_bg {
            overflow: hidden;
            img {
                display: block;
                min-width: 100%;
            }
        }
        .login_con_box {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 420px;
            .login_logo {
                img {
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    margin-bottom: 20px;
                }
            }
        }
        .login_wel {
            position: absolute;
            top: 30%;
            left: 10%;
            display:none;
            .wel_p1 {
                font-size: 4rem;
                font-weight: 600;
                color: #fff;
            }
            .wel_p2 {
                font-size: 2.5rem;
                color: #fff;
                margin-top: 15px;
            }
        }
        .login_plat {
            border-radius: 5px;
            background: rgba(255,255,255,.8);
            overflow: hidden;
            h3 {
                font-size: 2.5rem;
                color: #272727;
                font-weight: 600;
                text-align: center;
                text-align: center;
                padding-top: 30px;
                padding-bottom: 20px;
            }
            .platcon {
                padding: 25px 35px;
                height: 330px;
                .guide_item {
                    button {
                        display: block;
                        cursor: pointer;
                        border: none;
                        outline: none;
                        width: 90%;
                        margin-left: auto;
                        margin-right: auto;
                        background: #ef0212;
                        color: #fff;
                        font-size: 20px;
                        border-radius: 25px;
                        padding: 12px 0;
                        margin-top: 33px;
                        margin-bottom: 75px;
                        &:hover {
                            background: #da1b28;
                        }
                    }
                }

            }
        }
    }
    @media all and (max-width: 1200px) {
        .guide {
            .login_logo {
                top: 12%;
            }
        }
    }
    @media all and (max-width: 768px) {
        .guide {
            .login_con_box {
                position: initial;
                width: 100%;
                transform: translate(0,0);
                .login_logo {
                    position: absolute;
                    top: -45%;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
</style>