<template>
  <div class="login">
    <div class="login_bg">
      <img :src= loginBgBlean?loginBg:mobileLoginBg alt="bg">
    </div>
  <div class="login_con_box">
    <div class="login_logo">
      <img :src= loginLogo alt="logo">
    </div>
    <div class="login_wel">
      <p class="wel_p1">欢迎来到</p>
      <p class="wel_p2">{{ loginUnivercity }}</p>
    </div>
    <div class="login_plat">
      <h3>{{titleName}}</h3>
      <div class="platcon">
<!--          用户账号-->
        <div class="login_user login_item">
            <i class="iconfont" :class="{icon40one:loginBgBlean,iconuser:loginIcon}"></i>
            <input type="text" :class="isError?'asd':''" :placeholder="userNameP" maxlength="18" v-model="username" @click="changeAsd">
        </div>
<!--          用户密码-->
        <div class="login_pass login_item">
            <i class="iconfont" :class="{iconpassword:loginBgBlean,iconpassword1:loginIcon}" style="font-size: 16px"></i>
            <input :type="this.registration_data.pwdType" :class="isError?'asd':''" :placeholder="passwordP" v-model="password" @click="changeAsd">
            <img :src="this.registration_data.src" class="input_eye" @click="changeType()"/>
        </div>
<!--          验证码-->
        <div class="login_item login_item_piccheck">
            <i class="iconfont iconyanzhengma icon_yanzhengma"></i>
            <input class="login_piccheck" type="text" placeholder="验证码" v-model="captchacode">
            <img class="login_piccheck_img" ref="updatecap" @click="updateCode" :src="codeImg" alt="点击换一张">
        </div>
        <p class="login_tips" :class="isError?'login_tips_block':''">{{tipMsg}}</p>
<!--          登录按钮-->
        <div class="">
            <button class="login_btn" @click="loginBtn">登录</button>
        </div>
<!--          其他-->
        <div class="login_other login_item">
            <div class="login_other_ty" @click="goIdentity"><span v-show="identBlean">统一身份证登录</span></div>
            <p>
                <span @click="goReg" v-show="regBlean">立即注册</span>
                <span @click="goForget" v-show="forgetBlean">忘记密码</span>
            </p>
        </div>

<!--          移动端结构-->
          <div class="mobile_login">
              <p class="mobile_login_h"><span>您还可以使用以下方式登录</span></p>
              <img :src=mobileLoginImg alt="logo" @click="goIdentity">
              <p class="moble_p_btn" @click="goIdentity">统一身份认证登录</p>
          </div>

      </div>
    </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'login',
    data() {
      return {
        // 绑定数据对象
        next_url: '',
        app_id:'',
        titleName: window.g.titleName,
        userNameP: window.g.userNameP,
        passwordP: window.g.passwordP,
        username:'',
        password:'',
        captchacode:'',
        codeImg: '',
        tipMsg: '提示语',
        loginUnivercity:'',
        loginBg: '',
        mobileLoginBg: require('../../assets/images/mobil_login_bg.png'),
        loginLogo: '',
        mobileLoginImg: '',
        isError:false,
        loginBgBlean: true,
        loginIcon: false,
        identBlean: true,
        regBlean: true,
        forgetBlean: true,
        ssoUrl: '',
        regUrl: '',
        forgetUrl: '',
        registration_data:{
          pwdType:"password",
          src:require("../../assets/images/colse_eyes.png")
        }
      }
    },
      mounted() {
          this.getLoginInfo()//获取信息
          this.getBrowser()//获取当前浏览器类型
          this.loginBgChange()//背景图片切换
          this.updateCode()//页面加载的验证码
          this.getUrl()//获取url参数
      },
      methods: {
          // 获取url参数
          getUrl(){
            this.next_url = this.$units.getUrlKey("next_url");
            this.app_id = this.$units.getUrlKey("app_id");
          },
          loginBgChange(){
            if(window.screen.width > 768) {
                this.loginBgBlean = true
                this.loginIcon = false
            }else {
                this.loginBgBlean = false
                this.loginIcon = true
            }
          },
          changeAsd() {
            this.isError = false
          },
          goIdentity() {//统一身份认证登录，外链
            // this.$router.push('/')
            // window.location.href = "http://www.baidu.com";
            // window.open("http://www.baidu.com","_blank");
              window.location.href = this.ssoUrl
          },
          goReg() {//立即注册，外链
            // this.$router.push('/')
            // window.open("http://www.baidu.com","_blank");
            window.location.href = this.regUrl
          },
          goForget() {//忘记密码，外链
            // this.$router.push('/')
            // window.open("http://www.baidu.com","_blank");
            window.location.href = this.forgetUrl
          },
          getBrowser(){
              const Sys = {};
              const ua = navigator.userAgent.toLowerCase();
              const re = /(msie|firefox|chrome|opera|version).*?([\d.]+)/;
              const m = ua.match(re);
              try{
                  Sys.browser = m[1].replace(/version/, "'safari");
                  Sys.ver = m[2];
              }catch(e)
              {
                  console.log("getBrowserInfo fail.")
              }
              // console.log(Sys)

          },
          // 获取信息
          getLoginInfo: function(){
            this.$http.get('/api/v1/public/login',{})
              .then((res)=>{

                  if (res.data.code ==1) {
                      console.log("信息请求成功")
                      if (res.data.data.enable_sso == 1) {
                          this.ssoUrl = res.data.data.sso_url
                      }else{
                          // 值为false
                          this.identBlean = false
                      }
                      if (res.data.data.enable_forget == 1) {
                          this.forgetBlean = true
                      }else{
                          // 值为false
                          this.forgetBlean = false
                      }
                      if (res.data.data.enable_register == 1) {
                          this.regBlean = true
                      }else{
                          // 值为false
                          this.regBlean = false
                      }
                      this.regUrl = res.data.data.register_url;
                      this.forgetUrl = res.data.data.forget_url;

                      if(res.data.data.pc_background_img !== '') {
                          this.loginBg = res.data.data.pc_background_img;
                      }else {
                          this.loginBg = require('../../assets/images/login_bg.jpg')
                      }
                      if(res.data.data.wap_background_img !== '') {
                          this.mobileLoginBg = res.data.data.wap_background_img;
                      }else {
                          this.mobileLoginBg = require('../../assets/images/mobil_login_bg.png')
                      }
                      if(res.data.data.logo_img !== '') {
                          this.loginLogo = res.data.data.logo_img;
                      }else {
                          this.loginLogo = ''
                      }
                      if(res.data.data.badge_img !== '') {
                          this.mobileLoginImg = res.data.data.badge_img;
                      }else {
                          this.mobileLoginImg = require('../../assets/images/logo_mobile_login.png')
                      }
                      if (res.data.data.school_name !== '') {
                          this.loginUnivercity = res.data.data.school_name
                      }else {
                          this.loginUnivercity = ''
                      }
                  }else {
                      console.log("请求失败")
                  }
              })
          },
          // 密码隐藏/显示
          changeType(){
              this.registration_data.pwdType = this.registration_data.pwdType==='password'?'text':'password';
              this.registration_data.src=this.registration_data.src==require("../../assets/images/colse_eyes.png")?require("../../assets/images/open_eyes.png"):require("../../assets/images/colse_eyes.png");
          },
          //获取验证码
          updateCode: function() {
            this.$http.get('/api/v1/public/captcha?=${Math.random()}',{
                responseType: 'arraybuffer'
            })
            .then((res)=>{
                let codeImg =  'data:image/png;base64,' + btoa(
                    new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
                )
                this.codeImg = codeImg
            })
          },
        // 登录账号
        loginBtn: function () {
            // if (!(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/).test(this.username)) {
            //     return this.$message('请输入正确格式的工号或学号')
            // }
            // if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/.test(this.password))) {
            //     return this.$message('请输入6-12位数字加字母的密码');
            // }
            this.$http.post('/api/v1/public/login',{
                u_name: this.username,
                u_pass: this.sha1.hex_sha1(this.password),
                v_code: this.captchacode,
                next_url: this.next_url,
                app_id: this.app_id
            })
            .then((res) => {

                if(res.data.code == 1) {
                    // const userInfo = JSON.stringify(res.data.data.userinfo)
                    // const user_token = res.data.data.userinfo.user_token
                    // window.localStorage.setItem('userInfo',userInfo)
                    // window.localStorage.setItem('user_token',user_token)
                    this.$message.success('登录成功')
                    // this.$router.push(res.data.next)
                    window.location.href = res.data.next
                }else {
                    this.isError = true
                    this.$message.error('登录失败，请重新登录')
                    this.tipMsg = res.data.message
                    this.$refs.updatecap.click()//登录失败则刷新验证码
                    // return this.$toast('登录失败，请重新登录')
                }
            });
        },

    }
  }
</script>

<style lang="scss" scoped>
  .login {
    position: relative;
      .asd {
          /*border-color: #ef0212 !important;*/
          color: #ef0212 !important;
      }
      .login_tips_block {
          opacity: 1 !important;
      }
    .login_bg {
      overflow: hidden;
      img {
        display: block;
        min-width: 100%;
      }
    }
    .login_con_box {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 420px;
        .login_logo {
            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                margin-bottom: 20px;
            }
        }
    }
    .login_wel {
      position: absolute;
      top: 30%;
      left: 10%;
      display: none;
      .wel_p1 {
        font-size: 4rem;
        font-weight: 600;
        color: #fff;
      }
      .wel_p2 {
        font-size: 2.5rem;
        color: #fff;
        margin-top: 15px;
      }
    }
    .login_plat {
      border-radius: 5px;
      background: rgba(255,255,255,.8);
      overflow: hidden;
      h3 {
        font-size: 2.5rem;
        color: #272727;
        font-weight: 600;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 20px;
      }
      .platcon {
        padding: 25px 35px;
        height: 330px;
        .login_tips {
            display: block;
            text-align: center;
            margin-bottom: 10px;
            color: #ef0212;
            margin-top: -20px;
            /*position: absolute;*/
            /*<!--left: 50%;-->*/
            /*<!--bottom: 50px;-->*/
            /*<!--transform: translate(-50%,0);-->*/
            opacity: 0;
        }
        .login_item {
            width: 100%;
            display: flex;
            display: -webkit-flex;
            flex-direction: row;
            justify-content: space-between;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 25px;
            border: 1px solid #dadada;
            i {
                width: 10%;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: #fff;
                background: #bbb;
                font-size: 20px;
            }
            i.icon_yanzhengma {
                display: none;
            }
            input {
                width: 90%;
                border: none;
                outline: none;
                height: 40px;
                line-height: 40px;
                padding: 0 15px;
                color: #333;
                box-sizing: border-box;
            }
            input::-webkit-input-placeholder{
                color:#ababab;
            }
            input::-moz-placeholder{   /* Mozilla Firefox 19+ */
                color:#ababab;
            }
            input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
                color:#ababab;
            }
            input:-ms-input-placeholder{  /* Internet Explorer 10-11 */
                color:#ababab;
            }
            .input_eye {
                margin-left: -40px;
                margin-right: 20px;
                cursor: pointer;
                height: 20px;
                margin-top: 10px;
            }
            .login_piccheck {
                width: 210px;
                border: 1px solid #dadada;
                border-radius: 5px;
            }
            .login_piccheck_img {
                display: block;
                cursor: pointer;
            }
        }
        .login_item_piccheck {
            border: none;
        }
        button {
          display: block;
          width: 100%;
          border-radius: 5px;
          cursor: pointer;
          border: none;
          outline: none;
          margin-left: auto;
          margin-right: auto;
          height: 40px;
          line-height: 40px;
          color: #fff;
          background: #ef0212;
          &:hover {
              background: #da1b28;
          }
        }
        .login_other {
            border: none;
            margin-top: 18px;
            padding: 0 15px;
            box-sizing: border-box;
            .login_other_ty {
                color: #0b63e6;
                cursor: pointer;
            }
            p {
                display: flex;
                display: -webkit-flex;
                flex-direction: row;
                
                justify-content: flex-end;
                width: 145px;
                color: #ef0212;
                span {
                    cursor: pointer;
                    &:last-child {
                        margin-left: 10px;
                    }
                }
            }
        }
        .mobile_login {
            display: none;
        }
      }
    }
  }

/*移动端样式*/


@media all and (max-width: 1200px) {
      .login {
          .login_logo {
              top: 12%;
          }
      }
  }

@media all and (max-width: 768px) {
    .login {
        .login_con_box {
            position: initial;
            width: 100%;
            transform: translate(0,0);
            .login_logo {
                position: absolute;
                top: -45%;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .login_wel {
            display: none;
        }
        .login_plat {
            h3 {
                font-size: 36px;
                color: #e30905;
                text-align: center;
                font-weight: 600;
                margin-bottom: 50px;
            }
            .platcon {
                overflow: auto;
                height: auto;
                padding: 25px 8.8%;
                box-sizing: border-box;
                .login_item {
                    border: none;
                    border-bottom: 1px solid #c3c2c2;
                    padding-bottom: 25px;
                    i {
                        background: none;
                        color: #606060;
                        font-size: 20px!important;
                    }
                    i.icon_yanzhengma {
                        display: block;
                    }
                    input {
                        font-size: 24px;
                    }
                    .login_piccheck {
                        border: none;
                        margin-left: -225px;
                    }
                }
                button {
                    height: 65px;
                    line-height: 65px;
                    font-size: 24px;
                    border-radius: 50px;
                    margin-top: 50px;
                }
                .login_tips {
                    margin-top: 0;
                    font-size: 16px;
                }
                .login_other {
                    display: none;
                }
                .mobile_login {
                    display: block;
                    margin-top: 85px;
                    .mobile_login_h {
                        font-size: 26px;
                        color: #6e6e6e;
                        text-align: center;
                        overflow: hidden;
                        span {
                            position: relative;
                            &:before {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 0;
                                width: 170px;
                                height: 1px;
                                background: #e3e3e3;
                                transform: translate(-105%,-50%);
                            }
                            &:after {
                                content: '';
                                position: absolute;
                                top: 50%;
                                right: 0;
                                width: 170px;
                                height: 1px;
                                background: #e3e3e3;
                                transform: translate(105%,-50%);
                            }
                        }
                    }
                    img {
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 45px;
                    }
                    .moble_p_btn {
                        font-size: 20px;
                        color: #a3a2a2;
                        text-align: center;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}
@media all and (max-width: 540px) {
    .login {
        .login_plat {
            h3 {
                font-size: 30px;
            }
            .platcon {
                .mobile_login {
                    margin-top: 60px;
                    .mobile_login_h {
                        font-size: 20px;
                    }
                }
                .login_item {
                    input {
                        font-size: 18px;
                    }
                    .login_piccheck {
                        margin-left: -49px;
                    }
                }
                .login_tips {

                }
                button {
                    height: 55px;
                    line-height: 55px;
                    font-size: 18px;
                }
            }
        }
    }
}
@media all and (max-width: 414px) {
    .login {
        .login_plat {

            .platcon {
                .mobile_login {

                    .mobile_login_h {

                    }
                }
                .login_item {
                    input {

                    }
                    .login_piccheck {
                        margin-left: 3px;
                        width: 50%;
                    }
                }
                button {

                }
            }
        }
    }
}

</style>